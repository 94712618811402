import { useEffect, useState, useMemo } from 'react'

import ReactHtmlParser from 'react-html-parser'

import { TextAPI } from '../../../api/TextAPI'

import styles from './BestPriceGuarantee.module.scss';

import Image from "next/legacy/image"
import uorBest from '../../../images/logos/uor.svg'

const textTypes = ['best_price_guarantee_banner_header', 'best_price_guarantee_banner_body']

export function BestPriceGuarantee() {

  const [showDetails, setShowDetails] = useState(false);

  const [ header, setHeader ] = useState('')
  const [ body, setBody ] = useState('')

  const textAPI = useMemo(() => new TextAPI(process.env.NEXT_PUBLIC_API_BASE_URL ?? ''), [])

  useEffect(() => {
    textAPI.getMultipleByType(textTypes).then(texts => {
      const headerText = texts.find(t => t.text_type === textTypes[0])
      const bodyText = texts.find(t => t.text_type === textTypes[1])
      if (headerText) {
        setHeader(headerText.body)

        if (bodyText) {
          setBody(bodyText.body)
        }
      }
    })
  }, [textAPI])

  const handleToggleClick = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };

  return header ? (
    <aside className={ styles.banner }>

      <div className={ styles.header }>

        <div className={ styles.logo }>
          <Image
            alt="Universal Orlando Resort"
            src={uorBest}
          />
        </div>

        <div className={ styles.text }>
          { ReactHtmlParser(header) }
        </div>

        { body && (
          <a className={`${styles.toggle} ${showDetails ? styles.open : ''}`} href="#na" onClick={handleToggleClick}><span className="visuallyHidden">Hide/show detail</span></a>
        )}

      </div>

      {showDetails === true && body && (
        <div className={ styles.body }>
          { ReactHtmlParser(body) }
        </div>
      )}


    </aside>
  ) : null
}
