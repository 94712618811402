import styles from './CartQuantityPicker.module.scss';
import { useContext, useEffect } from 'react';

import { formatPrice } from '../../../utils/pricingUtils'
import { HotelSearchContext, HotelSearchContextType } from '../../../contexts/HotelSearchContext';

type CartQuantityPickerProps = {
  ticketPrice: string,
  showTicketPrice: boolean,
  showTotalPrice: boolean,
  qty: number,
  setQty: (qty: number) => void,
  loading: boolean,
  leftText: string,
  leftSubText: string,
  numDays?: string,
  type?: string
}

export function CartQuantityPicker({ ticketPrice, showTicketPrice, showTotalPrice, qty, setQty, loading, leftText, leftSubText, numDays, type }: CartQuantityPickerProps) {
  const { hotelSearchRooms } = useContext<HotelSearchContextType>(HotelSearchContext);

  // If there's a hotel stay, default park ticket & express pass quantities to match the traveling party
  // useEffect(()=> {
  //   if (hotelSearchRooms && hotelSearchRooms.length) {
  //     let ticketQty = 0;

  //     // Tickets with one ticket type - e.g Express passes (no child/adult tickets)
  //     if (!leftText.includes('Child') && !leftText.includes('Adult')) {
  //       if (hotelSearchRooms.length === 1) {
  //         ticketQty += hotelSearchRooms[0].adultsQty + hotelSearchRooms[0].childrenQty;
  //       } else {
  //         hotelSearchRooms.forEach(room => {
  //           ticketQty += room.adultsQty + room.childrenQty;
  //         });
  //       }
  //     }

  //     // Child park ticket - 3-9yrs
  //     if (leftText.includes('Child')) {
  //       // One hotel room with children
  //       if (hotelSearchRooms.length === 1 && hotelSearchRooms[0].childrenQty > 0) {
  //         const guestsUnder10 = hotelSearchRooms[0].childrenAges.filter(age => age < 10);
  //         ticketQty += guestsUnder10.length;
  //       }

  //       // Multiple hotel rooms
  //       if (hotelSearchRooms.length > 1) {
  //         hotelSearchRooms.forEach((room) => {
  //           // If children in that room, filter them by age
  //           if (room.childrenQty > 0) {
  //             const guestsUnder10 = room.childrenAges.filter(age => age < 10);
  //             ticketQty += guestsUnder10.length;
  //           }
  //         });
  //       }
  //     }

  //     // Adult park ticket - 10yrs+
  //     if (leftText.includes('Adult')) {
  //       // One hotel room
  //       if (hotelSearchRooms.length === 1) {

  //         // Add adults
  //         if (hotelSearchRooms[0].adultsQty > 0) {
  //           ticketQty += hotelSearchRooms[0].adultsQty;
  //         }

  //         // If room has children, add guests above 10yrs as adults
  //         if (hotelSearchRooms[0].childrenQty > 0) {
  //           const guestsOver10 = hotelSearchRooms[0].childrenAges.filter(age => age >= 10);
  //           ticketQty += guestsOver10.length;
  //         }
  //       }

  //       // Multiple hotel rooms
  //       if (hotelSearchRooms.length > 1) {
  //         hotelSearchRooms.forEach(room => {
  //           // Add adults
  //           if (room.adultsQty > 0) {
  //             ticketQty += room.adultsQty;
  //           }

  //           // If room has children, add guests above 10yrs as adults
  //           if (room.childrenQty > 0) {
  //             const guestsOver10 = room.childrenAges.filter(age => age >= 10);
  //             ticketQty += guestsOver10.length;
  //           }
  //         });
  //       }
  //     }

  //     setQty(ticketQty);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [hotelSearchRooms]);

  const ticketPriceDiv = showTicketPrice ? (
    <p className={ styles.price }>
      <strong className={ styles.main }>&pound;{ ticketPrice } </strong>
      { numDays ? <em className={ styles.sub }>{numDays} days</em> : null }
    </p>
  ) : null

  const totalPriceDiv = showTotalPrice ? (
    <p className={ styles.price }>
      <strong className={ styles.main }>&pound;{ formatPrice(parseFloat(ticketPrice) * qty) } </strong>
    </p>
  ) : null

  return (
    <div className={ styles.container }>
      <div className={styles.priceAndLabel}>
      <p className={ styles.title }>
        <strong className={ styles.main }>{ leftText } </strong>
        <em className={ styles.sub }>{ leftSubText }</em>
      </p>
      { ticketPriceDiv }
      </div>
      <div className={`${styles.qtyChangerContainer} ${loading ? styles.loading : ''}`}>
        {
          type === 'rooms' ? (null)
          : (
            <>
            <button
              onClick={() => qty ? setQty(qty - 1) : null}
              className={`${styles.qtyChanger} ${styles.qtyChangerRemove} ${qty === 0 ? styles.qtyChangerDisabled : ''}`}>
              <span className={styles.visuallyHidden}>Remove a ticket</span>
            </button>
            <span className={ styles.qty }>{ qty }</span>
            <button
              onClick={ () => setQty(qty + 1) }
              className={`${styles.qtyChanger} ${styles.qtyChangerAdd}`}>
              <span className={styles.visuallyHidden}>Add a ticket</span>
            </button>
            </>
          )
        }
      </div>
      { totalPriceDiv }
    </div>
  )
}
