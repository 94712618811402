import { ProductType } from '../types/ProductType'

export function formatPrice(price: number | string): string {

  let output = typeof(price) === 'number' ? price.toFixed(2) : price

  if (output.match(/\.00$/)) {
    output = output.substring(0, output.length - 3)
  }

  return output
}

/** Return the lowest Adult price if found, otherwise lowest of any price. */
export function getFromPrice(product: ProductType): number {

  let minAdultPrice = 0
  let minOtherPrice = 0

  product.tickets.forEach(ticket => {

    if (ticket.type === 'Adult') {
      const adultPrice = parseFloat(ticket.price_from)
      if (minAdultPrice === 0 || adultPrice < minAdultPrice) minAdultPrice = adultPrice
    } else {
      const otherPrice = parseFloat(ticket.price_from)
      if (minOtherPrice === 0 || otherPrice < minOtherPrice) minOtherPrice = otherPrice
    }
  })

  return minAdultPrice ? minAdultPrice : minOtherPrice
}
