import { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'

import Calendar, { ViewCallback, CalendarTileProperties } from 'react-calendar'
import { getDaysInMonth } from 'date-fns'

import Popup from 'reactjs-popup'

import styles from './CalendarTicketPopup.module.scss'

import { ProductType, TicketAvailability } from '../../../../types/ProductType'
import { DataLayerAPI } from '../../../../api/DataLayerAPI';
import { CartContext } from '../../../../contexts/CartContext';
import { format, utcToZonedTime } from "date-fns-tz";

type CalendarTicketPopupProps = {
  popupOpen: boolean,
  onClose: (event?: React.SyntheticEvent | KeyboardEvent | TouchEvent | MouseEvent) => void,
  product: ProductType,
  qtyPicker?: JSX.Element | null,
  childQtyPicker?: JSX.Element | null,
  adultQtyPicker?: JSX.Element | null,
  addToBasketBtn: (dateId: string, callback: () => void) => ReactElement,
  setPopupOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  setDateAdultPrice?: Function,
  setDateChildPrice?: Function,
  isDatedTicket ?: boolean | any,
}
const dataLayerAPI = new DataLayerAPI()
async function loadAvailability(productId: string, date: Date, setAvailability: Function, setAdultAvailability: Function, setChildAvailability: Function,   setLoadingAvailability: Function, setSelectedDate: Function, setSelectedDatePrice: Function, hasHotel: boolean, isDatedTicket: boolean) {

  setLoadingAvailability(true)

  // Hot fix for issue where sometimes date is in the past.
  const today = new Date();
  if (date < today) {
    date = today;
  }
  // const dateFrom = format(utcToZonedTime(new Date(date),'UTC'),'yyyy-MM-dd',{ timeZone: 'UTC' })
  // const dateTo = format(utcToZonedTime(new Date(date),'UTC'),'yyyy-MM-',{ timeZone: 'UTC' }) + getDaysInMonth(date)
  const dateFrom = format(new Date(date),'yyyy-MM-dd',{ timeZone: 'UTC' });
  const dateTo = format(new Date(date),'yyyy-MM-',{ timeZone: 'UTC' }) + getDaysInMonth(date)

  const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/products/${productId}?date_from=${dateFrom}&date_to=${dateTo}`

  const result = await fetch(url)

  if (result.status === 200) {
    const productResult: ProductType | null = await result.json()
    if (productResult) {
      if (isDatedTicket) {
        setAdultAvailability(productResult.tickets.find(t => t.type === "Adult")?.availability)
        setChildAvailability(productResult.tickets.find(t => t.type === "Child")?.availability)
      } else {
        setAvailability(productResult.tickets[0].availability)
      }
      const firstAvailableDate = productResult.tickets[0].availability?.find(avail => avail.date >= new Date().toISOString().substring(0, 10)) ?? null

      if (firstAvailableDate && !hasHotel) {
        setSelectedDate(new Date(firstAvailableDate.date))
        setSelectedDatePrice(firstAvailableDate.price)
      }
    } else {
      console.warn("Unexpected product result", productResult)
    }

  } else {
    console.warn("Failed to fetch availability at " + url)
  }
  setLoadingAvailability(false)
}

function getAvailabilityForDate(availability: TicketAvailability[], date: Date) {
  return availability.find(a => a.date === format(date, 'yyyy-MM-dd'))
}

export function CalendarTicketPopup({ popupOpen, setPopupOpen, onClose, product, qtyPicker, childQtyPicker, adultQtyPicker, addToBasketBtn, setDateAdultPrice, setDateChildPrice, isDatedTicket }: CalendarTicketPopupProps) {

  let hotelCheckInDate:string | undefined = undefined;

  const {cart} = useContext(CartContext)
  const productAvailability = product.calendar_first_available_month ? new Date(product.calendar_first_available_month) : new Date()
  const [selectedDate, setSelectedDate] = useState(hotelCheckInDate ? new Date(hotelCheckInDate) : productAvailability)
  const [selectedDatePrice, setSelectedDatePrice] = useState(0)
  const [selectedDateAdultPrice, setSelectedDateAdultPrice] = useState(0)
  const [selectedDateChildPrice, setSelectedDateChildPrice] = useState(0)
  const [availability, setAvailability] : [ availability: TicketAvailability[], setAvailability: Function ] = useState([])
  const [adultAvailability, setAdultAvailability] : [ adultAvailability: TicketAvailability[], setAdultAvailability: Function ] = useState([])
  const [childAvailability, setChildAvailability] : [ childAvailability: TicketAvailability[], setChildAvailability: Function ] = useState([])
  const [loadingAvailability, setLoadingAvailability] = useState(true)

  const cartTickets = cart.data.tickets;
  if (cartTickets.length > 0) {
    cartTickets.map((ticket) => {
      if (ticket.tags.includes('rooms')) {
        hotelCheckInDate = ticket.attributes['5'].join('');
      }
    });
  }

  // There's currently a bug with reactjs-popup which means the server-side
  // output will be slightly different to the client-side output, resulting
  // in a warning on the client side.
  // https://github.com/yjose/reactjs-popup/issues/215
  // So here we're using useEffect() to only render the popup on the client side.
  const [ renderPopup, setRenderPopup ] = useState(false)
  useEffect(() => setRenderPopup(true), [ setRenderPopup ])

  const [showDetails, setShowDetails] = useState(false);

  const handleToggleClick = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };

  // Handle date clicks:
  useEffect(() => {
    if (!isDatedTicket) {
      const avail = getAvailabilityForDate(availability, selectedDate)
      avail && setSelectedDatePrice(avail.price)
    } else {
      const adultAvail = getAvailabilityForDate(adultAvailability, selectedDate)
      const childAvail = getAvailabilityForDate(childAvailability, selectedDate)
      adultAvail && setSelectedDateAdultPrice(adultAvail.price)
      setDateAdultPrice && adultAvail && setDateAdultPrice(adultAvail.price)
      childAvail && setSelectedDateChildPrice(childAvail.price)
      setDateChildPrice && childAvail && setDateChildPrice(childAvail.price)
    }
  }, [selectedDate, availability, adultAvailability, isDatedTicket, childAvailability, setDateAdultPrice, setDateChildPrice])

  // Load availability when popup is opened/reopened:
  useEffect(() => {
    if (popupOpen) {
      const startDate = product.calendar_first_available_month ? new Date(product.calendar_first_available_month) : new Date()
      const checkInDate = hotelCheckInDate ? new Date(hotelCheckInDate.split('-').slice(0,-1).join('-')) : startDate
      const hasHotelSelected = hotelCheckInDate !== undefined ? true : false;
      loadAvailability(product.id, checkInDate, setAvailability, setAdultAvailability, setChildAvailability,  setLoadingAvailability, setSelectedDate, setSelectedDatePrice, hasHotelSelected, isDatedTicket)

      // If hotel stay added, set starting date same as the hotel stay
      if (hotelCheckInDate !== undefined) setSelectedDate(new Date(hotelCheckInDate));
    }
  }, [product, popupOpen, hotelCheckInDate, isDatedTicket])


  // Handle month changes:
  // const onChangeMonth = (action: string, activeStartDate: Date, value: Date, view: Detail) => {
  const hasHotelSelected = hotelCheckInDate !== undefined ? true : false;
  const onChangeMonth: ViewCallback = ({ activeStartDate }) => loadAvailability(product.id, activeStartDate, setAvailability, setAdultAvailability, setChildAvailability, setLoadingAvailability, setSelectedDate, setSelectedDatePrice, hasHotelSelected, isDatedTicket)

  const calendarDiv = useRef<HTMLInputElement>(null)

  const getTileContent = ({ date }: CalendarTileProperties): JSX.Element => {
    const avail = getAvailabilityForDate((availability.length !== 0 ? availability : adultAvailability), date)
    return avail ? <div className={ styles.price }>£{ avail.price }</div> : <></>
  }

  const getTileDisabled = ({ date }: CalendarTileProperties): boolean => {
    const avail = getAvailabilityForDate(availability.length !== 0 ? availability : adultAvailability, date)
    return avail ? false : true
  }

  const selectedDateId = getAvailabilityForDate(availability.length !== 0 ? availability : adultAvailability, selectedDate)?.date_id

  return renderPopup ? (
    <Popup open={popupOpen} onClose={() => {
      onClose
      setPopupOpen ? setPopupOpen(false) : null
      dataLayerAPI.logModalAction('Modal Closed','Express Pass Calendar',product.title)
      }} modal lockScroll onOpen={() => dataLayerAPI.logModalAction('Modal Opened','Express Pass Calendar',product.title)}>
      {(close: Function) => (
        <div className={ `${styles.modal} ${loadingAvailability ? styles.loading : ''}` }>
          <button className={styles.close} onClick={ () => close() }>
            <span className='visuallyHidden'>Close</span>
          </button>
          <div className={styles.popupHeader}>
            <h3>{ product.title }</h3>
          </div>
          <div className={ `popup-body-calendar ${styles.popupBody}` }>
            <div>
              <h4>Description</h4>
              { ReactHtmlParser(product.desc) }
              <h4>Ticket includes</h4>
              { ReactHtmlParser(product.ticket_includes) }
              <h4>
                <a className={`${styles.toggle} ${showDetails ? styles.open : ''}`} href="#na" onClick={handleToggleClick}>Conditions</a>
              </h4>
              {showDetails === true && ReactHtmlParser(product.conditions) }
            </div>
            <div className={ styles.calendar }>
              <Calendar
                onChange={ setSelectedDate }
                value={ selectedDate }
                onActiveStartDateChange={ onChangeMonth }
                minDate={ new Date() }
                inputRef={ calendarDiv }
                tileContent={ getTileContent }
                tileDisabled={ getTileDisabled }
                minDetail='month'
                maxDetail='month'
              />
              <div className={ styles.selectedDate }>
                Selected date: { format(selectedDate, 'dd/MM/yyyy') }
              </div>
              <div className={styles.pricesSection}>
              {!isDatedTicket &&
              <><div className={styles.qtySection}>
                    {qtyPicker}
                  </div><div className={styles.selectedDatePrice}>
                      £{selectedDatePrice}
                    </div></>}
              {isDatedTicket && <>
                <div className={styles.qtySection}>
                  {adultQtyPicker}
                </div>
                  <div className={styles.qtySection}>
                    {childQtyPicker}
                  </div>
                </>}
                </div>
            </div>
          </div>
          <div className={ `popup-footer ${styles.popupFooter}` }>
            <button
              className={styles.btnAlt}
              onClick={ () => close() }
            >CANCEL</button>
            { selectedDateId ? addToBasketBtn(selectedDateId, () => close()) : null }
          </div>
        </div>
      )}
    </Popup>
  ) : null
}

